// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
    /** spacing **/
    --ion-space-xx-small: 0.25rem;
    --ion-space-x-small: 0.5rem;
    --ion-space-small: 0.75rem;
    --ion-space-medium: 1rem;
    --ion-space-large: 1.5rem;
    --ion-space-x-large: 2rem;
    --ion-space-xx-large: 2.5rem;
    --ion-space-xxx-large: 4rem;
    --ion-space-xxxx-large: 8rem;

    /** font **/
    --ion-font-family-base: 'Roboto', sans-serif;
    --ion-font-family-secondary: 'Inconsolata', serif;

    --ion-font-size-extra-small: 0.625rem;
    --ion-font-size-base: 1rem;
    --ion-font-size-medium: 1.15rem;
    --ion-font-size-large: 1.25rem;
    --ion-font-size-x-large: 1.5rem;
    --ion-font-size-xx-large: 2rem;

    --ion-font-size-base-inconsolata: 1.2rem;
    --ion-font-size-medium-inconsolata: 1.1rem;

    --ion-font-weight-light: 300;
    --ion-font-weight-base: 500;
    --ion-font-weight-heading: 700;

    /** primary **/
    --ion-color-primary: #3880ff;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3171e0;
    --ion-color-primary-tint: #4c8dff;

    /** secondary **/
    --ion-color-secondary: #3dc2ff;
    --ion-color-secondary-rgb: 61, 194, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #36abe0;
    --ion-color-secondary-tint: #50c8ff;

    /** tertiary **/
    --ion-color-tertiary: #5260ff;
    --ion-color-tertiary-rgb: 82, 96, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #4854e0;
    --ion-color-tertiary-tint: #6370ff;

    /** status colors **/
    --ion-color-accepted: #fde74c;
    --ion-color-accepted-rgb: 253, 231, 76;
    --ion-color-accepted-contrast: #000000;
    --ion-color-accepted-contrast-rgb: 0, 0, 0;
    --ion-color-accepted-shade: #dfcb43;
    --ion-color-accepted-tint: #fde95e;

    --ion-color-confirmed: #0cca4a;
    --ion-color-confirmed-rgb: 12, 202, 74;
    --ion-color-confirmed-contrast: #000000;
    --ion-color-confirmed-contrast-rgb: 0, 0, 0;
    --ion-color-confirmed-shade: #0bb241;
    --ion-color-confirmed-tint: #24cf5c;

    --ion-color-started: #f8b43c;
    --ion-color-started-rgb: 248, 180, 60;
    --ion-color-started-contrast: #000000;
    --ion-color-started-contrast-rgb: 0, 0, 0;
    --ion-color-started-shade: #da9e35;
    --ion-color-started-tint: #f9bc50;

    /** invalid username/password **/
    --ion-color-invalid-login: #d32f2f;
    --ion-color-invalid-login-rgb: 211, 47, 47;
    --ion-color-invalid-login-contrast: #ffffff;
    --ion-color-invalid-login-contrast-rgb: 255, 255, 255;
    --ion-color-invalid-login-shade: #ba2929;
    --ion-color-invalid-login-tint: #d74444;

    --ion-color-login-warning: #ed6c02;
    --ion-color-login-warning-rgb: 237, 108, 2;
    --ion-color-login-warning-contrast: #000000;
    --ion-color-login-warning-contrast-rgb: 0, 0, 0;
    --ion-color-login-warning-shade: #d15f02;
    --ion-color-login-warning-tint: #ef7b1b;

    /** success **/
    --ion-color-success: #00a441;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    /** warning **/
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    /** danger **/
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    /** background **/
    --ion-background-color: #f7f7f7;
}
